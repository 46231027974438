<template>
  <div id="main-wrapper">

    <div class="main-content is-footer">

      <!--7گزینه-->
      <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
      <vcl-Table  v-if="!pageloading" ></vcl-Table>
      <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>

      <v-container v-else >
        <v-row>
          <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="37">mdi-seal</v-icon> منو مدیریت </p>
        </v-row>


        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#fcba03">
             حسابداری
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/hesabdari/3'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>نمایش صندوق جایزه</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/hesabdari/4'});" :loading="loading" depressed color="#e63766" height="30" style="width: 100%"> <b>نمایش صندوق مسابقات</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/hesabdari/5'});" :loading="loading" depressed color="#f799d0" height="30" style="width: 100%"> <b>نمایش صندوق پورسانت</b></v-btn>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#f799d0">
              مدیریت فرمها
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/9'});" :loading="loading" depressed color="#fcba03" height="30" style="width: 100%"> <b>مدیریت فرمهای سیزده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/4'});" :loading="loading" depressed color="#02b002" height="30" style="width: 100%"> <b>مدیریت فرمهای چهارده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/3'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>مدیریت فرمهای شانزده نشاط</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/16'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>مدیریت فرمهای شانزده گنجینه</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/7'});" :loading="loading" depressed color="#e63766" height="30" style="width: 100%"> <b>مدیریت فرمهای هفده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/30'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>مدیریت فرمهای بیست تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatform/5'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>مدیریت فرمهای پانزده بازی</b></v-btn>


            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#02b002">
              آرشیو فرمها
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/9'});" :loading="loading" depressed color="#fcba03" height="30" style="width: 100%"> <b>آرشیو فرمهای سیزده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/4'});" :loading="loading" depressed color="#02b002" height="30" style="width: 100%"> <b>آرشیو فرمهای چهارده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/3'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>آرشیو فرمهای شانزده نشاط</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/16'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>آرشیو فرمهای شانزده گنجینه</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/7'});" :loading="loading" depressed color="#e63766" height="30" style="width: 100%"> <b>آرشیو فرمهای هفده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/30'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>آرشیو فرمهای بیست تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriatarchive/5'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>آرشیو فرمهای پانزده بازی</b></v-btn>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#4a8ff7">
              تصحیح فرمها
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/9'});" :loading="loading" depressed color="#fcba03" height="30" style="width: 100%"> <b>تصحیح فرمهای سیزده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/4'});" :loading="loading" depressed color="#02b002" height="30" style="width: 100%"> <b>تصحیح فرمهای چهارده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/3'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>تصحیح فرمهای شانزده نشاط</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/16'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>تصحیح فرمهای شانزده گنجینه</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/7'});" :loading="loading" depressed color="#e63766" height="30" style="width: 100%"> <b>تصحیح فرمهای هفده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/30'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>تصحیح فرمهای بیست تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/modiriattarchive/5'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>تصحیح فرمهای پانزده بازی</b></v-btn>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#d3d921">
              هدیه به سایت
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/profile'});" :loading="loading" depressed color="#fcba03" height="30" style="width: 100%"> <b>هدیه به سایت</b></v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#f15101">
              فتوکل بازیها
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/photokol/9'});" :loading="loading" depressed color="#fcba03" height="30" style="width: 100%"> <b>فتوکل فرمهای سیزده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/4'});" :loading="loading" depressed color="#02b002" height="30" style="width: 100%"> <b>فتوکل فرمهای چهارده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/3'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>فتوکل فرمهای شانزده نشاط</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/16'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>فتوکل فرمهای شانزده گنجینه</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/7'});" :loading="loading" depressed color="#e63766" height="30" style="width: 100%"> <b>فتوکل فرمهای هفده تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/30'});" :loading="loading" depressed color="#e0005f" height="30" style="width: 100%"> <b>فتوکل فرمهای بیست تایی</b></v-btn>
              <v-divider style="margin-top:5px;"></v-divider>
              <v-btn class="flex" @click="$router.push({path:'/photokol/5'});" :loading="loading" depressed color="#ed805f" height="30" style="width: 100%"> <b>فتوکل فرمهای پانزده بازی</b></v-btn>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-expansion-panels class="mb-6">
          <v-expansion-panel >
            <v-expansion-panel-header expand-icon="mdi-menu-down" color="#476e03" style="color: #ffffff">
              مدیریت صندوق
            </v-expansion-panel-header><v-divider style="margin-top:5px;"></v-divider>
            <v-expansion-panel-content>
              <v-btn class="flex" @click="$router.push({path:'/tabadol'});" :loading="loading" depressed color="#5b64cf" height="30" style="width: 100%"> <b>تبادلات مالی شما</b></v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-container>




    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src


import axios from "axios";
import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';

export default {
  name: 'gamest',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,
  },
  data () {
    return {
      show: true,
      games:[],
      url:'http://g.samanyaar.ir/soccerp/',
      pageloading: false,
      loading: false,
    }
  },
  mounted() {
    let self = this;
    self.pageloading= true;

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),

  },
  methods:{
    randomColor() {
      const r = () => Math.floor(250 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },



  },
  watch:{}
}
</script>

<style>
  .product-slider .v-btn--active{
    background: transparent
  }
  .product-slider .v-progress-linear {
    height: 2px  !important;
  }
  .add-to-cart{
    height: 80%;
    background: #e91e63 !important;
    color:#fff;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 13px;
  }
  .product-property{
    margin: 0;
    padding: 0;

  }
  .middle {
    display: table-cell;
    vertical-align: middle;
  }
  .product-property li{
    list-style: none;
    display: inline-block;
    width: 100%;
  }
  .product-property li > .product-param-list,
  .product-property li > .product-value-list{
      /* background: #f6f6f6; */
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 7px 10px 7px 7px;
      margin-bottom: 15px;
      display: inline-block;
      border-bottom: solid 1px #ddd;
  }
  .product-property li > .product-param-list{
    width: 35%;
    margin-left: 5%;
    font-size: 13px;
    color:black;
    vertical-align: top;
    border-bottom: none;
  }
  .product-property li > .product-value-list{
    width: 60%;
    font-size: 12px;
  }
  .product-property li:last-child .product-value-list{
    border-bottom: none;
  }
  .last-comment{
    border-color: #ddd;
    border:solid 1px #ddd !important;
  }
</style>
